<template>
    <v-dialog v-model="showModal" :max-width="indHabilitaTabelaFornecedor? '1200px' : '800px'">
      <v-form ref="form" lazy-validation>
        <v-card>
          <v-card-title v-if="!indHabilitaTabelaFornecedor">
            <span class="headline"> {{ $tc('label.justificativa', 1) }}</span>
          </v-card-title>
          <v-card-title v-if="indHabilitaTabelaFornecedor">
            <v-icon dark color="warning" x-large >report_problem</v-icon>
            <span class="warning--text font-weight-bold text-uppercase pt-3 pl-2">
              {{ $tc('title.atencao_alerta', 1) }}
            </span>
          </v-card-title>
          <v-card-text>
            <v-container class="grey lighten-5" v-if="indHabilitaJustificativa && !indHabilitaTabelaFornecedor">
                <v-col cols="12">
                    <v-text-field
                      ref="justificativa"
                      :label="`${$t('label.justificativa')} *`"
                      :rules="[rules.required]"
                      maxlength="200"
                      required
                      v-model.trim="novaJustificativa">
                    </v-text-field>
                  </v-col>
            </v-container>
            <div>
              <v-container v-if="indHabilitaTabelaFornecedor">
                <v-row>
                  <v-col cols="12" sm="12">
                    <div>
                      <p class="mb-0 font-weight-regular">
                        {{ $t('message.grupo_fornecedor_alterado', { grupoAtualId: grupoAtual.id, grupoAtualNome: grupoAtual.nomExtensao, grupoNovoId:  grupoNovo.id, grupoNovoNome: grupoNovo.nomExtensao}) }}
                      </p>
                    </div>
                    <v-container>
                      <v-row no-gutters>
                      <v-col cols="5">
                      <p class="font-weight-black m-0 p-0">
                        {{ $t('message.apuracoes_impactadas', { countApuracoes: listaApuracoesImpactadas.length }) }}
                      </p>
                    </v-col>
                    </v-row>
                    </v-container>
                    <v-data-table
                      :headers="headerApuracoesImpactadas"
                      :items="listaApuracoesImpactadas"
                      :no-data-text="$t('label.tabela_sem_contrato_substituicao')"
                      >
                      <template v-slot:[`item.entidade`]="{ item }">
                        {{ item.entidade }}
                      </template>
                      <template v-slot:[`item.id_entidade`]="{ item }">
                        {{ item.id_entidade }}
                      </template>
                      <template v-slot:[`item.tipo_acao`]="{ item }">
                        {{ item.tipo_acao }}
                      </template>
                      <template v-slot:[`item.id_apuracao`]="{ item }">
                        {{ item.id_apuracao }}
                      </template>
                      <template v-slot:[`item.vigencia_apuracao`]="{ item }">
                        {{ item.vigencia_apuracao }}
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="somenteLeitura" text @click.native="cancela">{{ $t('label.fechar') }}</v-btn>
            <v-btn text @click.native="cancela">{{ $t('label.cancelar') }}</v-btn>
            <v-btn
              v-if="!indHabilitaTabelaFornecedor"
              color="primary darken-1"
              text
              @click.native="salvar"
            >{{ $t('label.salvar') }}</v-btn>
            <v-btn
            v-if="indHabilitaTabelaFornecedor"
            color="primary darken-1"
            text
            @click.native="confirmacao"
          >{{ $t('label.confirmar') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </template>
<script>
import {
  buscaAutocomplete,
  triggerSelecao,
} from '@/common/functions/autocomplete-utils';

export default {
  data() {
    return {
      fornecedorResource: this.$api.fornecedor(this.$resource),
      showModal: false,
      listaApuracoesImpactadas: [],
      novaJustificativa: '',
      idsApuracao: '',
      idsApuracaoList: [],
      countApuracoesImpactadas: 0,
      indAlteracaoGrupo: false,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
      totalPage: 0,
      pagination: {
        itemsPerPage: 10,
        page: 1,
      },
      headerApuracoesImpactadas: [
        {
          text: '', value: 'checkbox', width: '1%', sortable: false,
        },
        {
          text: this.$tc('label.id', 1), value: 'entidade', width: '1%', align: 'center', sortable: false,
        },
        {
          text: this.$tc('label.id_entidade', 1), value: 'id_entidade', width: '12%', align: 'center', sortable: false,
        },
        {
          text: this.$tc('label.tipo_acao', 1), value: 'tipo_acao', width: '6%', align: 'center', sortable: false,
        },
        {
          text: this.$tc('label.id_apuracao', 1), value: 'id_apuracao', width: '5%', align: 'center', sortable: false,
        },
        {
          text: this.$tc('label.vigencia_apuracao', 1), value: 'vigencia_apuracao', width: '10%', align: 'center', sortable: false,
        },
      ],
    };
  },
  props: {
    obrigatorio: Boolean,
    salvarJustificativa: Function,
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    indHabilitaJustificativa: {
      type: Boolean,
      default: false,
    },
    indHabilitaTabelaFornecedor: {
      type: Boolean,
    },
    grupoAtual: {
      type: Object,
      default: {},
    },
    grupoNovo: {
      type: Object,
      default: {},
    },
    fornecedorDadosRequisicao: {
      type: Object,
      default: {},
    },
  },
  methods: {
    buscaAutocomplete,
    triggerSelecao,
    open() {
      this.novaJustificativa = '';
      this.showModal = true;
      if (this.indHabilitaJustificativa) {
        setTimeout(() => {
          this.$refs.justificativa.focus();
        }, 2E2);
      }
    },
    openTabelaFornecedor() {
      this.indHabilitaTabelaFornecedor = true;
      this.novaJustificativa = '';
      this.showModal = true;
      this.buscarTabelaFornecedor();
    },
    cancela() {
      this.novaJustificativa = '';
      this.showModal = false;
    },
    salvar() {
      if (!this.$refs.form.validate()) return;
      if (this.indHabilitaJustificativa) {
        this.$emit('agree', this.novaJustificativa, this.indAlteracaoGrupo, this.idsApuracao);
      } else {
        this.$emit('agree', this.novaJustificativa);
      }
      this.showModal = false;
    },
    confirmacao() {
      this.indHabilitaTabelaFornecedor = false;
      this.indHabilitaJustificativa = true;
      this.indAlteracaoGrupo = true;
      if (this.listaApuracoesImpactadas.length) {
        this.listaApuracoesImpactadas.forEach((listaApuracao) => {
          this.idsApuracaoList.push(listaApuracao.id_apuracao);
        });
        this.idsApuracao = this.idsApuracaoList.toString();
      }
    },
    buscarTabelaFornecedor() {
      const { idFornecedor } = this.fornecedorDadosRequisicao;
      this.fornecedorResource.buscarApuracoesImpactadas({ idFornecedor }).then((res) => {
        this.listaApuracoesImpactadas = res.data;
        this.countApuracoesImpactadas = this.listaApuracoesImpactadas.length;
        if (this.countApuracoesImpactadas === 0) {
          this.indHabilitaTabelaFornecedor = false;
          this.indHabilitaJustificativa = true;
          this.open();
        } else {
          this.showModal = true;
        }
      });
    },
  },
  mounted() {
  },
};
</script>
